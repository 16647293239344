<template>
    <div class="mt-3 px-xl-5">
        <b-row>
            <h6 class="page-title pb-2 mt-3">
                {{ $t("superintendences.title") }}
            </h6>
        </b-row>
        <b-row class="justify-content-end mb-3">
            <b-col class="text-end">
                <b-button
                    class="main-btn me-4"
                    @click="
                        $router.push({
                            name: 'alerts',
                        })
                    "
                >
                    <b-icon-bell scale="1.5" />
                </b-button>
                <b-button
                    class="main-btn me-4"
                    @click="
                        $router.push({
                            name: 'risk-management',
                        })
                    "
                >
                    <b-icon-grip-horizontal scale="1.5" />
                </b-button>
                <b-button
                    class="main-btn"
                    @click="
                        $router.push({
                            name: 'send-superintendence-invitation',
                        })
                    "
                >
                    <b-icon-person-plus scale="1.5" />
                </b-button>
            </b-col>
            <b-col xl="2" md="2" cols="2">                
                <select class="form-select form-select-sm rounded-0 mt-1" v-model="status" @change="filterTable">
                    <option :value="null" selected hidden>Estado</option>
                    <option :value="null"></option>
                    <option :value="true">Activo</option>
                    <option :value="false">Inactivo</option>
                </select>
            </b-col>
            <b-col xl="4" md="6" cols="6">
                <b-input-group class="mt-1">
                    <b-form-input
                        :placeholder="$t('tables.search')"
                        class="rounded-0"
                        size="sm"
                        v-model="search"
                        @keydown.enter="filterTable"
                    />
                    <b-input-group-append>
                        <b-button
                            class="main-btn"
                            size="sm"
                            @click="filterTable"
                        >
                            <b-icon-search />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                    <b-table
                        ref="superintendencesTable"
                        stacked="md"
                        small
                        hover
                        select-mode="single"
                        selectable
                        show-empty
                        :empty-text="$t('superintendences.empty-table')"
                        :filter="filter"
                        :busy.sync="isBusy"
                        :fields="fields"
                        :items="retrieveData"
                        :current-page="currentPage"
                        :per-page="take"
                        @filtered="onFiltered"
                        @row-clicked="goSuperitendence"
                    >
                        <template #cell(disable)="data">
                            <div>
                                <b-button
                                    :disabled="!allowDisable(data.item)"
                                    @click="openDisable(data.item)"
                                    :variant="data.item.isEnabled ? 'success' : 'danger'"
                                >
                                    <b-icon icon="x-circle" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-4 mb-5">
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="take"
                    size="sm"
                    class="my-0 justify-content-end"
                >
                    <template #first-text>
                        <span><b-icon-chevron-double-left scale="1.3" /></span>
                    </template>
                    <template #prev-text>
                        <span><b-icon-chevron-left scale="1.3" /></span>
                    </template>
                    <template #next-text>
                        <span><b-icon-chevron-right scale="1.3" /></span>
                    </template>
                    <template #last-text>
                        <span><b-icon-chevron-double-right scale="1.3" /></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
        <DisableSuperintendenceModal
            :isOpen="openedDisable"
            :id="superintendenceId"
            @close="closeDisable"
            @disabled="refreshTable"
        />
    </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";
import { mapGetters } from "vuex";

import DisableSuperintendenceModal from "./DisableSuperintendenceModal";

export default {
    name: "superintendencias",
    components: {
        DisableSuperintendenceModal,
    },
    data() {
        return {
            fields: [
                { key: "name", label: this.$t("superintendences.name") },
                { key: "email", label: this.$t("general.email") },
                {
                    key: "creationDate",
                    label: this.$t("general.creation-date"),
                },
                {
                    key: "identificationType",
                    label: this.$t("general.identification-type"),
                },
                {
                    key: "identification",
                    label: this.$t("general.identification"),
                },
                { key: "disable", label: "" },
            ],
            search: "",
            status: null,
            filter: null,
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,
            superintendenceId: null,
            openedDisable: false,
        };
    },
    computed: {
        ...mapGetters("application", ["isSuperUser"]),
    },
    methods: {
        allowDisable(superintendence) {
            return superintendence.isEnabled && this.isSuperUser;
        },
        openDisable(superintendence) {
            this.openedDisable = true;
            this.superintendenceId = superintendence.id;
        },
        closeDisable() {
            this.openedDisable = false;
        },
        filterTable() {
            this.filter = this.search;
            this.refreshTable();
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                    status: this.status
                };
                let response = await RepositoryFactory.superintendence.getPaged(
                    payload
                );
                this.rows = response.data.total;
                let items = response.data.items;
                for (let i = 0; i < items.length; i++) {
                    const superintendence = items[i];
                    superintendence.creationDate = this.parseAndConvertDate(
                        superintendence.creationDate
                    );
                    superintendence.identificationType =
                        this.getIdentificationTypeString(
                            superintendence.identificationType
                        );
                }
                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        refreshTable() {
            this.$refs.superintendencesTable.refresh();
        },
        goSuperitendence(superintendence) {
            this.$router.push({
                name: "superintendence",
                params: { id: superintendence.id },
            });
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        getIdentificationTypeString(type) {
            switch (type) {
                case 0:
                    return this.$t("general.cedula");
                case 1:
                    return this.$t("general.passport");
            }
            return "";
        },
    },
};
</script>